const Admin_Panel = 'info@logodesignexpertx.com';
const PHONE = '+61 489 951 988';
const PHONE_HREF = 'tel:61489951988';
// const PHONE_TWO = '+61 489 951 988';
// const PHONE_TWO_HREF = 'tel:61489951988';
const EMAIL = 'info@logodesignexpertx.com';
const EMAIL_HREF = 'mailto:info@logodesignexpertx.com';
const CONTACT_EMAIL = 'info@logodesignexpertx.com';
const CONTACT_EMAIL_HREF = 'mailto:info@logodesignexpertx.com';
const ADDRESS = '220 S Almaden Ave, San Jose, CA 95113, USA';
const WEBSITE_NAME = 'Logo Design Expertx';
const SITE_CURRENCY_SYMBOLS = '$';
const WEBSITE_LOGO =
  '';

export{
    Admin_Panel,
    PHONE,
    PHONE_HREF,
    // PHONE_TWO,
    // PHONE_TWO_HREF,
    EMAIL,
    EMAIL_HREF,
    CONTACT_EMAIL,
    CONTACT_EMAIL_HREF,
    ADDRESS,
    WEBSITE_NAME,
    SITE_CURRENCY_SYMBOLS,
    WEBSITE_LOGO,
}